import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Harryscorner from './HarrysCorner';
import Charter from '../api/getCharter';
import News from '../api/getNews';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: '0px solid transparent', // Remove the border
}));

export default function BasicGrid() {
  return (
    <Box
      sx={{
        maxWidth: "1183px",
        background: '#f5f5f5'
      }}
    >
      <Grid
        container
        spacing={1}
        padding={1}
        sx={{
          position: "relative"
        }}
      >
        <Grid item xs={12} sm={12} md={3.75} ld={3.75} xl={3.75}>
          <Item
            style={{}}
            sx={{
              maxWidth: "810px",
            }}
          >
            <Charter />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={4.76} ld={4.76} xl={4.76}
                   sx={{ border: 0 }}
                   >
          <Item
            style={{}}
            sx={{
              maxWidth: "810px",
              border: 0
            }}
          >
            <News />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={3.4} ld={3.4} xl={3.4}>
          <Item
            style={{}}
            sx={{
              maxWidth: "810px",
              //background: '#f5f5f5',
            }}
          >
            <Harryscorner />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
